import React from 'react'
import { Typography, makeStyles, Grid, Hidden } from '@material-ui/core'
import { Timeline, TimelineItem, TimelineConnector, TimelineDot, TimelineContent, TimelineSeparator } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
    itemRoot: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        color: theme.palette.text.primary
    },
    dotRoot: {
        backgroundColor: theme.palette.text.primary,
        borderRadius: "100px",
        padding: '5px',
        minWidth: "100px"
    }
}))

export default function TimelineComponent({ processList = [] }) {
    const classes = useStyles()
    return (
        <div>
            <Hidden xsDown implementation='css'>
                <Timeline align='alternate'>
                    {
                        processList.map((item) => {
                            return <TimelineItem key={item.id}>
                                <TimelineSeparator>
                                    <TimelineDot>
                                        <div className={classes.dotRoot} >
                                            <Typography variant='body2' style={{ color: 'white', textAlign: 'center' }}>{item.process}</Typography>
                                        </div>
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Typography
                                        className={classes.itemRoot}
                                    >{item.detail}</Typography>
                                </TimelineContent>
                            </TimelineItem>
                        })
                    }
                </Timeline>
            </Hidden>
            <Hidden smUp implementation='css'>
                <Grid container spacing={4}>
                    {
                        processList.map((item) => {
                            return <Grid item key={item.id} xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <div className={classes.dotRoot} >
                                            <Typography variant='body2' style={{ color: 'white', textAlign: 'center' }}>{item.process}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            className={classes.itemRoot}
                                        >{item.detail}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        })
                    }
                </Grid>
            </Hidden>
        </div >
    )
}
